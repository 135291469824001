export const ActionsSearchDefault = {
	GET_SEARCH_DEFAULT: "get_search_default",
	LOAD_GET_SEARCH_DEFAULT: "load_get_search_default",
	RES_GET_SEARCH_DEFAULT: "res_get_search_default",
	ERR_GET_SEARCH_DEFAULT: "err_get_search_default",
	HYDRATE: "HYDRATE",
};

export function failureSearchDefault(err) {

	return {
		type: ActionsSearchDefault.ERR_GET_SEARCH_DEFAULT,
		err,
	};

}

export function loadSearchDefault(params = {}) {

	return {
		type: ActionsSearchDefault.GET_SEARCH_DEFAULT,
		...params,
	};

}

export function loadGetSearchDefault(params = {}) {

	return {
		type: ActionsSearchDefault.LOAD_GET_SEARCH_DEFAULT,
		...params,
	};

}

export function loadSearchDefaultSuccess(data) {

	return {
		type: ActionsSearchDefault.RES_GET_SEARCH_DEFAULT,
		data,
	};

}
