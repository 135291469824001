import { ActionsArticleCategorySlug } from './actions'
import { HYDRATE } from 'next-redux-wrapper'

const initialState = { data: {}, status: 'fetch_init', message: '' }

function storeArticleCategorySlug(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.storeArticleCategorySlug }
    case ActionsArticleCategorySlug.LOAD_GET_ARTICLE_CATEGORY_SLUG:
      return { ...state, data: state.data, status: 'fetch_loading' }
    case ActionsArticleCategorySlug.RES_GET_ARTICLE_CATEGORY_SLUG:
      return { ...state, data: action.data, status: 'fetch_result' }
    case ActionsArticleCategorySlug.ERR_GET_ARTICLE_CATEGORY_SLUG:
      return {
        ...state,
        status: "fetch_error",
        message: "Error...",
        data: { data: [] },
      };
    default:
      return { ...state }
  }
}

export default storeArticleCategorySlug
