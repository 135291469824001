import { combineReducers } from 'redux'

import { storeSelf } from '@fdn/profile_store'
import storeEditorialTag from '../editorial_tag_store/lib/store'
import storeArticles from '../articles_store/lib/store'
import storeArticleCategorySlug from '../article_category_slug_store/lib/store'
import storePopularArticles from '../popular_articles_store/lib/store'
import storeArticleAuthor from '../article_author_store/lib/store'
import storeArticleArchives from '../article_archives_store/lib/store'
import storeSearchArticle from '../search_article/lib/store'
import storeSearchDefault from "../SearchDefault/lib/store";
import storeQuickSearch from "../QuickSearch/lib/store";
import storeWallet from '../AlloWallet/lib/store'
import storePoint from '../AlloPoint/lib/store'
import storeCompleting from '../CompleteProfile/lib/store';
import storeSynergyMedia from '../SynergyMedia/lib/store';

const rootReducer = combineReducers({
  storeSelf,
  storeEditorialTag,
  storeArticles,
  storeArticleCategorySlug,
  storePopularArticles,
  storeArticleAuthor,
  storeArticleArchives,
  storeSearchArticle,
  storeSearchDefault,
	storeQuickSearch,
  storeWallet,
  storePoint,
  storeCompleting,
  storeSynergyMedia,
})

export default rootReducer
