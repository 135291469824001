import { call, put, takeEvery } from 'redux-saga/effects'
import {
  ActionsPopularArticles,
  API_POPULAR_ARTICLES,
  failurePopularArticles,
  loadPopularArticlesSuccess,
} from './actions'

// Function Fetch Popular Articles
function* loadDataPopularArticles(params = {}) {
  try {
    const endpoint = `${
        ["canary", "production"].includes(params.payload.api_env)
          ? API_POPULAR_ARTICLES.PROD
          : API_POPULAR_ARTICLES.DEV
      }?limit=${params.payload.limit || '4'}`,
      Authorization = params.payload.Authorization,
      headers = params.payload.is_server
        ? { ...params.payload.headers, ...(Authorization && { Authorization }) }
        : {
            ...params.payload.headers,
            ...(Authorization && { Authorization }),
            endpoint: api_endpoint,
          },
      method = 'GET',
      options = { headers, method }

    const response = yield call(fetch, endpoint, options)
    if (typeof response.status !== 'undefined' && response.status >= 200 && response.status < 300) {
      const self = yield response.json()
      yield put(loadPopularArticlesSuccess(self))
    } else {
      throw response
    }
  } catch (err) {
    yield put(failurePopularArticles(err))
  }
}

export default function* rootSaga() {
  yield takeEvery(
    ActionsPopularArticles.GET_POPULAR_ARTICLES,
    loadDataPopularArticles,
  )
}
