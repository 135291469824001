// Actions CategorySlug
export const ActionsArticleCategorySlug = {
  GET_ARTICLE_CATEGORY_SLUG: 'get_article_category_slug',
  LOAD_GET_ARTICLE_CATEGORY_SLUG: 'load_get_article_category_slug',
  RES_GET_ARTICLE_CATEGORY_SLUG: 'result_get_article_category_slug',
  ERR_GET_ARTICLE_CATEGORY_SLUG: 'error_get_article_category_slug',
  HYDRATE: 'HYDRATE',
}

// Function if Failure
export function failureArticleCategorySlug(error) {
  return {
    type: ActionsArticleCategorySlug.ERR_GET_ARTICLE_CATEGORY_SLUG,
    error,
  }
}

// Function if LoadCategorySlug
export function loadArticleCategorySlug(params = {}) {
  return {
    type: ActionsArticleCategorySlug.GET_ARTICLE_CATEGORY_SLUG,
    ...params,
  }
}

export function loadGetArticleCategorySlug(data) {
  return {
    type: ActionsArticleCategorySlug.LOAD_GET_ARTICLE_CATEGORY_SLUG,
    data,
  }
}

// Function if LoadCategorySlug
export function loadArticleCategorySlugSuccess(data) {
  return {
    type: ActionsArticleCategorySlug.RES_GET_ARTICLE_CATEGORY_SLUG,
    data,
  }
}
