export const ActionsQuickSearch = {
	GET_QUICK_SEARCH: "get_quick_search",
	LOAD_GET_QUICK_SEARCH: "load_get_quick_search",
	RES_GET_QUICK_SEARCH: "res_get_quick_search",
	ERR_GET_QUICK_SEARCH: "err_get_quick_search",
	HYDRATE: "HYDRATE",
};

export function failureQuickSearch(err) {

	return {
		type: ActionsQuickSearch.ERR_GET_QUICK_SEARCH,
		err,
	};

}

export function loadQuickSearch(params = {}) {

	return {
		type: ActionsQuickSearch.GET_QUICK_SEARCH,
		...params,
	};

}

export function loadGetQuickSearch(params = {}) {

	return {
		type: ActionsQuickSearch.LOAD_GET_QUICK_SEARCH,
		...params,
	};

}

export function loadQuickSearchSuccess(data) {

	return {
		type: ActionsQuickSearch.RES_GET_QUICK_SEARCH,
		data,
	};

}
