// Actions ArticleArchives
export const ActionsArticleArchives = {
  GET_ARTICLE_ARCHIVES: 'get_article_archives',
  LOAD_GET_ARTICLE_ARCHIVES: 'load_get_article_archives',
  RES_GET_ARTICLE_ARCHIVES: 'result_get_article_archives',
  ERR_GET_ARTICLE_ARCHIVES: 'error_get_article_archives',
  HYDRATE: 'HYDRATE',
}
// Function if FailureArticleArchives
export function failureArticleArchives(error) {
  return {
    type: ActionsArticleArchives.ERR_GET_ARTICLE_ARCHIVES,
    error,
  }
}

// Function if LoadArticleArchivess
export function loadArticleArchives(params = {}) {
  return { type: ActionsArticleArchives.GET_ARTICLE_ARCHIVES, ...params }
}

export function loadGetArticleArchives(data) {
  return {
    type: ActionsArticleArchives.LOAD_GET_ARTICLE_ARCHIVES,
    data,
  }
}

// Function if LoadArticleArchivesSuccess
export function loadArticleArchivesSuccess(data) {
  return {
    type: ActionsArticleArchives.RES_GET_ARTICLE_ARCHIVES,
    data,
  }
}
