import { call, put, takeEvery } from 'redux-saga/effects'
import {
  ActionsSearchArticle,
  API_SEARCH_ARTICLE,
  failureSearchArticle,
  loadSearchArticleSuccess,
  loadGetSearchArticle,
} from './actions'

function* loadDataSearchArticle(params = {}) {
  yield put(loadGetSearchArticle());

  try {
    const endpoint = `${API_SEARCH_ARTICLE}?search=${params.payload.search}`,
      headers = params.payload.is_server
        ? { ...params.payload.headers }
        : {
            ...params.payload.headers,
            endpoint: api_endpoint,
          },
      method = 'GET',
      options = { headers, method }

    const response = yield call(fetch, endpoint, options)
    if (typeof response.status !== 'undefined' && response.status >= 200 && response.status < 300) {
      var data = yield response.json()
      var articles_data = []
      data.data.map((item) =>
        articles_data.push({
          id: item.id,
          date: item.created_at,
          image: item.image,
          url: item.url,
          title: item.title,
          text: item.excerpt,
          category: {
            name: item.category.length > 0 ? item.category[0].name : '',
            slug: item.category_slug,
            slugs: item.category_slugs,
          },
          author: {
            username: item.author.username,
            fullname: item.author.name,
          },
        }),
      )
      data.data = articles_data

      yield put(loadSearchArticleSuccess(data))
    }
  } catch (err) {
    yield put(failureSearchArticle(err))
  }
}

export default function* rootSaga() {
  yield takeEvery(
    ActionsSearchArticle.GET_SEARCH_ARTICLE,
    loadDataSearchArticle,
  )
}
