// Actions ArticleAuthor
export const ActionsArticleAuthor = {
  GET_ARTICLE_AUTHOR: 'get_article_author',
  LOAD_GET_ARTICLE_AUTHOR: 'load_get_article_author',
  RES_GET_ARTICLE_AUTHOR: 'result_get_article_author',
  ERR_GET_ARTICLE_AUTHOR: 'error_get_article_author',
  HYDRATE: 'HYDRATE',
}
// Function if FailureArticleAuthor
export function failureArticleAuthor(error) {
  return {
    type: ActionsArticleAuthor.ERR_GET_ARTICLE_AUTHOR,
    error,
  }
}

// Function if LoadArticleAuthors
export function loadArticleAuthor(params = {}) {
  return { type: ActionsArticleAuthor.GET_ARTICLE_AUTHOR, ...params }
}

export function loadGetArticleAuthor(data) {
  return {
    type: ActionsArticleAuthor.LOAD_GET_ARTICLE_AUTHOR,
    data,
  }
}

// Function if LoadArticleAuthorSuccess
export function loadArticleAuthorSuccess(data) {
  return {
    type: ActionsArticleAuthor.RES_GET_ARTICLE_AUTHOR,
    data,
  }
}
