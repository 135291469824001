import { call, put, takeEvery } from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsCompleteProfile,
	API_COMPLETE_PROFILE,
	failureCompleteProfile,
	loadCompleteProfileSuccess,
	loadGetCompleteProfile,
} from "./actions";

function* loadDataCompleteProfile(params = {}) {

	yield put(loadGetCompleteProfile({}));
	try {

		const endpoint = API_COMPLETE_PROFILE;
		const { Authorization } = params.payload;
		const headers = {
			...Env.HEADERS,
			...(Authorization && { Authorization }),
		};
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (response.status >= 200 && response.status < 300) {

			const data = yield response.json();
			if (data.meta.code === 200) {

				yield put(loadCompleteProfileSuccess(data));

			} else {

				yield put(failureCompleteProfile(data.meta?.message));

			}

		} else {

			yield put(failureCompleteProfile({
				code: response.status,
				status: response.statusText,
				message: "Error response status was unacceptable",
			}));

		}

	} catch (err) {

		yield put(failureCompleteProfile(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsCompleteProfile.GET_COMPLETE_PROFILE, loadDataCompleteProfile);

}
