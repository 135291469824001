import { HYDRATE } from 'next-redux-wrapper'
import { ActionsArticleAuthor } from './actions'

const initialState = { data: {}, status: 'fetch_init', message: '' }

function storeArticleAuthor(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.storeArticleAuthor }
    case ActionsArticleAuthor.LOAD_GET_ARTICLE_AUTHOR:
      return { ...state, data: state.data, status: 'fetch_loading' }
    case ActionsArticleAuthor.RES_GET_ARTICLE_AUTHOR:
      return { ...state, data: action.data, status: 'fetch_result' }
    case ActionsArticleAuthor.ERR_GET_ARTICLE_AUTHOR:
      return {
        ...state,
        status: "fetch_error",
        message: "Error...",
        data: { data: [] },
      };
    default:
      return { ...state }
  }
}

export default storeArticleAuthor
