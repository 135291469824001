import { HYDRATE } from "next-redux-wrapper";
import { ActionsSearchDefault } from "./actions";

const initialState = {
	data: {},
	meta: {},
	is_loading: true,
	error: null,
	status: "fetch_init",
	message: "",
};

function storeSearchDefault(state = initialState, action) {

	switch (action.type) {

	case HYDRATE:
		return { ...state, ...action.payload.storeSearchDefault };
	case ActionsSearchDefault.LOAD_GET_SEARCH_DEFAULT:
		return {
			...state,
			status: "fetch_loading",
			is_loading: true,
			error: null,
		};
	case ActionsSearchDefault.RES_GET_SEARCH_DEFAULT:
		return {
			...state,
			data: action.data.data,
			meta: action.data.meta,
			status: "fetch_result",
			is_loading: false,
			error: null,
		};
	case ActionsSearchDefault.ERR_GET_SEARCH_DEFAULT:
		return {
			...state,
			data: {},
			meta: {},
			status: "fetch_error",
			message: "Error...",
			is_loading: false,
			error: true,
		};
	default:
		return { ...state };

	}

}

export default storeSearchDefault;
