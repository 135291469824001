import { call, put, takeEvery, race, delay } from 'redux-saga/effects'
import {
  ActionsArticleArchives,
  failureArticleArchives,
  loadArticleArchivesSuccess,
  loadGetArticleArchives,
} from './actions'
import Env from 'Consts/env'

// Function Fetch Article Archives
function* loadDataArticleArchives(params = {}) {
  try {
    yield put(loadGetArticleArchives())

    const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/articleArchive`
    const headers = {
      ...Env.HEADERS,
      year: params.year || '',
      month: params.month || '',
      day: params.day||'',
      page: params.page || 1,
      authorization: params.authorization || '',
    }
    const method = 'GET'
    const options = { headers, method }
    const { timeout, response } = yield race({
      timeout: delay(Env.REQUEST_TIMEOUT),
      response: call(fetch, endpoint, options),
    })

    if (timeout) {
      yield put(failureArticleArchives(yield 'Error Timeout...'))
    } else {
      const dataArticleArchives = yield response.json()

      if (response.status !== 200) {
        yield put(failureArticleArchives(yield response.statusText))
      } else {
        yield put(loadArticleArchivesSuccess(yield dataArticleArchives))
      }
    }
  } catch (err) {
    yield put(failureArticleArchives(err))
  }
}

export default function* rootSaga() {
  yield takeEvery(
    ActionsArticleArchives.GET_ARTICLE_ARCHIVES,
    loadDataArticleArchives,
  )
}
