import { call, put, takeEvery, race, delay } from 'redux-saga/effects'
import {
  ActionsArticleAuthor,
  failureArticleAuthor,
  loadArticleAuthorSuccess,
  loadGetArticleAuthor,
} from './actions'
import Env from 'Consts/env'

// Function Fetch Article Author
function* loadDataArticleAuthor(params = {}) {
  try {
    yield put(loadGetArticleAuthor())

    const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/articleByAuthor?author_id=${params.author_id || ''}&page=${params.page || 1}`
    const headers = {
      ...Env.HEADERS,
      // author_id: params.author_id || '',
      // page: params.page || 1,
    }
    const method = 'GET'
    const options = { headers, method }
    const { timeout, response } = yield race({
      timeout: delay(Env.REQUEST_TIMEOUT),
      response: call(fetch, endpoint, options),
    })

    if (timeout) {
      yield put(failureArticleAuthor(yield 'Error Timeout...'))
    } else {
      const dataArticleAuthor = yield response.json()

      if (response.status !== 200) {
        yield put(failureArticleAuthor(yield response.statusText))
      } else {
        yield put(loadArticleAuthorSuccess(yield dataArticleAuthor))
      }
    }
  } catch (err) {
    yield put(failureArticleAuthor(err))
  }
}

export default function* rootSaga() {
  yield takeEvery(
    ActionsArticleAuthor.GET_ARTICLE_AUTHOR,
    loadDataArticleAuthor,
  )
}
