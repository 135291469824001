// Actions Articles
export const ActionsArticles = {
  GET_ARTICLES: 'get_articles',
  LOAD_GET_ARTICLES: 'load_get_articles',
  RES_GET_ARTICLES: 'result_get_articles',
  ERR_GET_ARTICLES: 'error_get_articles',
  HYDRATE: 'HYDRATE',
}

// Function if FailureArticles
export function failureArticles(error) {
  return {
    type: ActionsArticles.ERR_GET_ARTICLES,
    error,
  }
}

// Function if LoadArticles
export function loadArticles(params = {}) {
  return { type: ActionsArticles.GET_ARTICLES, ...params }
}

export function loadGetArticles(data) {
  return {
    type: ActionsArticles.LOAD_GET_ARTICLES,
    data,
  }
}

// Function if LoadArticlesSuccess
export function loadArticlesSuccess(data) {
  return {
    type: ActionsArticles.RES_GET_ARTICLES,
    data,
  }
}
