import { ActionsArticles } from './actions'
import { HYDRATE } from 'next-redux-wrapper'

const initialState = { data: {}, status: 'fetch_init', message: '' }

function storeArticles(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.storeArticles }
    case ActionsArticles.LOAD_GET_ARTICLES:
      return { ...state, data: state.data, status: 'fetch_loading' }
    case ActionsArticles.RES_GET_ARTICLES:
      return { ...state, data: action.data, status: 'fetch_result' }
    case ActionsArticles.ERR_GET_ARTICLES:
      return {
        ...state,
        status: "fetch_error",
        message: "Error...",
        data: { data: [] },
      };
    default:
      return { ...state }
  }
}

export default storeArticles
