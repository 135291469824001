export const ActionsCompleteProfile = {
	GET_COMPLETE_PROFILE: "get_complete_profile",
	LOAD_GET_COMPLETE_PROFILE: "load_complete_profile",
	RES_GET_COMPLETE_PROFILE: "res_get_complete_profile",
	ERR_GET_COMPLETE_PROFILE: "err_get_complete_profile",
	HYDRATE: "HYDRATE",
};

export const API_COMPLETE_PROFILE = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getCompleteProfile`;

export function failureCompleteProfile(err) {

	return {
		type: ActionsCompleteProfile.ERR_GET_COMPLETE_PROFILE,
		err,
	};

}

export function loadGetCompleteProfile() {

	return {
		type: ActionsCompleteProfile.LOAD_GET_COMPLETE_PROFILE,
		data: {},
	};

}

export function loadCompleteProfile(params = {}) {

	return {
		type: ActionsCompleteProfile.GET_COMPLETE_PROFILE,
		...params,
	};

}

export function loadCompleteProfileSuccess(data) {

	return {
		type: ActionsCompleteProfile.RES_GET_COMPLETE_PROFILE,
		data,
	};

}
