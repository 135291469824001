import { call, put, takeEvery, race, delay } from 'redux-saga/effects'
import {
  ActionsEditorialTag,
  failureEditorialTag,
  loadEditorialTagSuccess,
  loadGetEditorialTag,
} from './actions'
import Env from 'Consts/env'

// Function Fetch Article Tag
function* loadDataEditorialTag(params = {}) {
  try {
    yield put(loadGetEditorialTag())

    const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/articleByTagSlug`
    const headers = {
      ...Env.HEADERS,
      slug: params.slug || '',
      limit: params.limit || '',
      page: params.page || 1,
    }
    const method = 'GET'
    const options = { headers, method }
    const { timeout, response } = yield race({
      timeout: delay(Env.REQUEST_TIMEOUT),
      response: call(fetch, endpoint, options),
    })

    if (timeout) {
      yield put(failureEditorialTag(yield 'Error Timeout...'))
    } else {
      const dataEditorialTag = yield response.json()

      if (response.status !== 200) {
        yield put(failureEditorialTag(yield response.statusText))
      } else {
        yield put(loadEditorialTagSuccess(yield dataEditorialTag))
      }
    }
  } catch (err) {
    yield put(failureEditorialTag(err))
  }
}

export default function* rootSaga() {
  yield takeEvery(
    ActionsEditorialTag.GET_EDITORIAL_TAG,
    loadDataEditorialTag,
  )
}
