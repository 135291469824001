import { fork } from 'redux-saga/effects'

import { dispatchSelf } from '@fdn/profile_store'
import dispatchArticles from '../articles_store/lib/fetch'
import dispatchArticleCategorySlug from '../article_category_slug_store/lib/fetch'
import dispatchPopularArticles from '../popular_articles_store/lib/fetch'
import dispatchArticleAuthor from '../article_author_store/lib/fetch'
import dispatchEditorialTag from '../editorial_tag_store/lib/fetch'
import dispatchArticleArchives from '../article_archives_store/lib/fetch'
import dispatchSearchArticle from '../search_article/lib/fetch'
import dispatchQuickSearch from '../QuickSearch/lib/fetch';
import dispatchSearchDefault from "../SearchDefault/lib/fetch";
import dispatchWallet from "../AlloWallet/lib/fetch";
import dispatchPoint from "../AlloPoint/lib/fetch";
import dispatchCompleting from "../CompleteProfile/lib/fetch";
import dispatchSynergyMedia from "../SynergyMedia/lib/fetch";

export default function* rootSaga() {
  yield fork(dispatchSelf)
  yield fork(dispatchEditorialTag)
  yield fork(dispatchArticles)
  yield fork(dispatchArticleCategorySlug)
  yield fork(dispatchPopularArticles)
  yield fork(dispatchArticleAuthor)
  yield fork(dispatchArticleArchives)
  yield fork(dispatchSearchArticle)
  yield fork(dispatchQuickSearch);
	yield fork(dispatchSearchDefault);
  yield fork(dispatchWallet);
  yield fork(dispatchPoint);
  yield fork(dispatchCompleting);
  yield fork(dispatchSynergyMedia);
}
