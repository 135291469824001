export const ActionsWallet = {
	GET_WALLET: "get_wallet",
	LOAD_GET_WALLET: "load_wallet",
	RES_GET_WALLET: "res_get_wallet",
	ERR_GET_WALLET: "err_get_wallet",
	HYDRATE: "HYDRATE",
};

export const API_WALLET = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/AlloWallet/getInfo`;

export function failureWallet(err) {

	return {
		type: ActionsWallet.ERR_GET_WALLET,
		err,
	};

}

export function loadGetWallet() {

	return {
		type: ActionsWallet.LOAD_GET_WALLET,
		data: {},
	};

}

export function loadWallet(params = {}) {

	return {
		type: ActionsWallet.GET_WALLET,
		...params,
	};

}

export function loadWalletSuccess(data) {

	return {
		type: ActionsWallet.RES_GET_WALLET,
		data,
	};

}
