import {
	call, delay, put, race, takeEvery,
} from "redux-saga/effects";
import Env from "../../../consts/env";
import {
	ActionsQuickSearch,
	failureQuickSearch, loadGetQuickSearch, loadQuickSearchSuccess,
} from "./actions";

function* loadDataQuickSearch(params = {}) {

	try {

		yield put(loadGetQuickSearch());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getQuickSearch?keyword=${params.payload.keyword}`;

		const headers = { ...Env.HEADERS, page: params.payload.page };
		const method = "GET";
		const options = { headers, method };

		const { timeout, response } = yield race({
			timeout: delay(30000),
			response: call(fetch, endpoint, options),
		});

		if (timeout) {

			yield put(failureQuickSearch(yield "Error Timeout..."));

		} else {

			const dataQuickSearch = yield response.json();

			if (response.status !== 200) {

				yield put(failureQuickSearch(yield response.statusText));

			} else {

				yield put(loadQuickSearchSuccess(yield dataQuickSearch));

			}

		}

	} catch (err) {

		yield put(failureQuickSearch(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsQuickSearch.GET_QUICK_SEARCH, loadDataQuickSearch);

}
