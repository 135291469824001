import { ActionsPopularArticles } from './actions'
import { HYDRATE } from 'next-redux-wrapper'

const initialState = { data: {}, status: 'fetch_init', message: '' }

function storePopularArticles(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.storePopularArticles }
    case ActionsPopularArticles.LOAD_GET_POPULAR_ARTICLES:
      return { ...state, data: action.data, status: 'fetch_loading' }
    case ActionsPopularArticles.RES_GET_POPULAR_ARTICLES:
      return { ...state, data: action.data, status: 'fetch_result' }
    case ActionsPopularArticles.ERR_GET_POPULAR_ARTICLES:
      return { ...state, data: {}, status: 'fetch_error', message: 'Error...' }
    default:
      return { ...state }
  }
}

export default storePopularArticles
