import { HYDRATE } from "next-redux-wrapper";
import { ActionsCompleteProfile } from "./actions";
import { predefineCompletingModel } from "../helper/predefined";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeCompleteProfile(state = initialState, action) {

	switch (action.type) {

	case HYDRATE:
		return { ...state, ...action.payload.storeCompleteProfile };
	case ActionsCompleteProfile.LOAD_GET_COMPLETE_PROFILE:
		return { ...state, data: {}, status: "fetch_loading" };
	case ActionsCompleteProfile.RES_GET_COMPLETE_PROFILE:
		return {
			...state,
			data: process.env.NEXT_PUBLIC_ALLO_STATUS === "ON" ? predefineCompletingModel(action.data) : action.data,
			status: "fetch_result"
		};
	case ActionsCompleteProfile.ERR_GET_COMPLETE_PROFILE:
		return {
			...state,
			status: "fetch_error",
			message: action?.err || "Error...",
		};
	default:
		return { ...state };

	}

}

export default storeCompleteProfile;
