export const ActionsSearchArticle = {
  GET_SEARCH_ARTICLE: 'get_search_article',
  LOAD_GET_SEARCH_ARTICLE: 'load_get_search_article',
  RES_GET_SEARCH_ARTICLE: 'res_get_search_article',
  ERR_GET_SEARCH_ARTICLE: 'err_get_search_article',
  HYDRATE: 'HYDRATE',
}

export const API_SEARCH_ARTICLE = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/searchArticle`

export function loadGetSearchArticle() {
  return {
    type: ActionsSearchArticle.LOAD_GET_SEARCH_ARTICLE,
  }
}

export function failureSearchArticle(err) {
  return {
    type: ActionsSearchArticle.ERR_GET_SEARCH_ARTICLE,
    err,
  }
}

export function loadSearchArticle(params = {}) {
  return {
    type: ActionsSearchArticle.GET_SEARCH_ARTICLE,
    ...params,
  }
}

export function loadSearchArticleSuccess(data) {
  return {
    type: ActionsSearchArticle.RES_GET_SEARCH_ARTICLE,
    data,
  }
}
