// Actions PopularArticles
export const ActionsPopularArticles = {
  GET_POPULAR_ARTICLES: 'get_popular_articles',
  LOAD_GET_POPULAR_ARTICLES: 'load_get_popular_articles',
  RES_GET_POPULAR_ARTICLES: 'result_get_popular_articles',
  ERR_GET_POPULAR_ARTICLES: 'error_get_popular_articles',
  HYDRATE: 'HYDRATE',
}

// API Popular Posts
export const API_POPULAR_ARTICLES = {
  DEV: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/popularArticles`,
  PROD: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/popularArticles`,
}

// Function if Failure
export function failurePopularArticles(error) {
  return {
    type: ActionsPopularArticles.ERR_GET_POPULAR_ARTICLES,
    error,
  }
}

// Function if LoadPopularArticles
export function loadPopularArticles(params = {}) {
  return { type: ActionsPopularArticles.GET_POPULAR_ARTICLES, ...params }
}

// Function if LoadPopularArticles
export function loadPopularArticlesSuccess(data) {
  return {
    type: ActionsPopularArticles.RES_GET_POPULAR_ARTICLES,
    data,
  }
}
