import { wrapper } from '../store'
import '../containers/style.css'
// import { init } from 'Helpers/sentry'

// init()

function App({ Component, pageProps, err }) {
  return <Component {...pageProps} err={err} />
}
// export default App
export default wrapper.withRedux(App)
