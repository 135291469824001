export default {
  // Headers
  HEADERS: {
    key: process.env.NEXT_PUBLIC_KEY_API,
    version: '1.5',
    device: '3',
    'content-type': 'application/json',
  },
  HEADERS_WALLET: {
		key: "client03-TSbs94s3q5H9PP2yNPBr",
		version: "1.5",
		device: "3",
	},
  // Api timeout
  REQUEST_TIMEOUT: 5000,
};
