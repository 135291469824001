import { call, put, takeEvery, race, delay } from 'redux-saga/effects'
import {
  ActionsArticleCategorySlug,
  failureArticleCategorySlug,
  loadArticleCategorySlugSuccess,
  loadGetArticleCategorySlug
} from './actions'
import Env from 'Consts/env'
// Function Fetch Article
function* loadDataCategorySlug(params = {}) {
  try {
    yield put(loadGetArticleCategorySlug())

    const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/articleByCategorySlug?slug=${params.slug}&page=${params.page||1}&limit=${params.limit}`
    const headers = {
      ...Env.HEADERS,
    }
    const method = 'GET'
    const options = { headers, method }
    const { timeout, response } = yield race({
      timeout: delay(Env.REQUEST_TIMEOUT),
      response: call(fetch, endpoint, options),
    })

    if (timeout) {
      yield put(failureArticleCategorySlug(yield 'Error Timeout...'))
    } else {
      const dataArticleCategorySlug = yield response.json()

      if (response.status !== 200) {
        yield put(failureArticleCategorySlug(yield response.statusText))
      } else {
        yield put(loadArticleCategorySlugSuccess(yield dataArticleCategorySlug))
      }
    }
  } catch (err) {
    yield put(failureArticleCategorySlug(err))
  }
}

export default function* rootSaga() {
  yield takeEvery(
    ActionsArticleCategorySlug.GET_ARTICLE_CATEGORY_SLUG,
    loadDataCategorySlug,
  )
}