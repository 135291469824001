export const ActionsSynergyMedia = {
	GET_SYNERGY_MEDIA: "get_synergy_media",
	LOAD_GET_SYNERGY_MEDIA: "load_get_synergy_media",
	RES_GET_SYNERGY_MEDIA: "res_get_synergy_media",
	ERR_GET_SYNERGY_MEDIA: "err_get_synergy_media",
	HYDRATE: "HYDRATE",
};

export function failureSynergyMedia(err, params) {

	return {
		type: ActionsSynergyMedia.ERR_GET_SYNERGY_MEDIA,
		err,
		...params,
	};

}

export function loadSynergyMedia(params = {}) {

	return {
		type: ActionsSynergyMedia.GET_SYNERGY_MEDIA,
		...params,
	};

}

export function loadGetSynergyMedia(params = {}) {

	return {
		type: ActionsSynergyMedia.LOAD_GET_SYNERGY_MEDIA,
		...params,
	};

}

export function loadSynergyMediaSuccess(data, params) {

	return {
		type: ActionsSynergyMedia.RES_GET_SYNERGY_MEDIA,
		data,
		...params,
	};

}
