export const ActionsPoint = {
	GET_POINT: "get_point",
	LOAD_GET_POINT: "load_point",
	RES_GET_POINT: "res_get_point",
	ERR_GET_POINT: "err_get_point",
	HYDRATE: "HYDRATE",
};

export const API_POINT = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/AlloPoint/getInfo`;

export function failurePoint(err) {

	return {
		type: ActionsPoint.ERR_GET_POINT,
		err,
	};

}

export function loadGetPoint() {

	return {
		type: ActionsPoint.LOAD_GET_POINT,
		data: {},
	};

}

export function loadPoint(params = {}) {

	return {
		type: ActionsPoint.GET_POINT,
		...params,
	};

}

export function loadPointSuccess(data) {

	return {
		type: ActionsPoint.RES_GET_POINT,
		data,
	};

}
