import { ActionsSearchArticle } from './actions'
import { HYDRATE } from 'next-redux-wrapper'

const initialState = { data: [], status: 'fetch_init', message: '' }

function storeSearchArticle(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.storeSearchArticle }
    case ActionsSearchArticle.LOAD_GET_SEARCH_ARTICLE:
      return { ...state, data: [], status: 'fetch_loading' }
    case ActionsSearchArticle.RES_GET_SEARCH_ARTICLE:
      return { ...state, data: action.data, status: 'fetch_result' }
    case ActionsSearchArticle.ERR_GET_SEARCH_ARTICLE:
      return {
        ...state,
        data: action,
        status: 'fetch_error',
        message: 'Error...',
      }
    default:
      return { ...state }
  }
}

export default storeSearchArticle
