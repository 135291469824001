import {
	call, delay, put, race, takeEvery,
} from "redux-saga/effects";
import Env from "../../../consts/env";
import {
	ActionsSearchDefault,
	failureSearchDefault, loadGetSearchDefault, loadSearchDefaultSuccess,
} from "./actions";

function* loadDataSearchDefault(params = {}) {

	try {

		yield put(loadGetSearchDefault());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getSearchDefault`;

		const headers = {
			...Env.HEADERS,
			page: params.page,
			Authorization: params.Authorization,
		};

		const method = "GET";
		const options = { headers, method };

		const { timeout, response } = yield race({
			timeout: delay(Env.REQUEST_TIMEOUT),
			response: call(fetch, endpoint, options),
		});

		if (timeout) {

			yield put(failureSearchDefault(yield "Error Timeout..."));

		} else {

			const dataSearchDefault = yield response.json();

			if (response.status !== 200) {

				yield put(failureSearchDefault(yield response.statusText));

			} else {

				yield put(loadSearchDefaultSuccess(yield dataSearchDefault));

			}

		}

	} catch (err) {

		yield put(failureSearchDefault(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsSearchDefault.GET_SEARCH_DEFAULT, loadDataSearchDefault);

}
