import { HYDRATE } from "next-redux-wrapper";
import { ActionsQuickSearch } from "./actions";

const initialState = { data: [], status: "fetch_init", message: "" };
function storeQuickSearch(state = initialState, action) {

	switch (action.type) {

	case HYDRATE:
		return { ...state, ...action.payload.storeQuickSearch };
	case ActionsQuickSearch.LOAD_GET_QUICK_SEARCH:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsQuickSearch.RES_GET_QUICK_SEARCH:
		return { ...state, data: action.data.data, status: "fetch_result" };
	case ActionsQuickSearch.ERR_GET_QUICK_SEARCH:
		return {
			...state,
			data: action,
			status: "fetch_error",
			message: "Error...",
		};
	default:
		return { ...state };

	}

}

export default storeQuickSearch;
