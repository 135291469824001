// Actions EditorialTag
export const ActionsEditorialTag = {
  GET_EDITORIAL_TAG: 'get_editorial_tag',
  LOAD_GET_EDITORIAL_TAG: 'load_get_editorial_tag',
  RES_GET_EDITORIAL_TAG: 'result_get_editorial_tag',
  ERR_GET_EDITORIAL_TAG: 'error_get_editorial_tag',
  HYDRATE: 'HYDRATE',
}
// Function if FailureEditorialTag
export function failureEditorialTag(error) {
  return {
    type: ActionsEditorialTag.ERR_GET_EDITORIAL_TAG,
    error,
  }
}

// Function if LoadEditorialTags
export function loadEditorialTag(params = {}) {
  return { type: ActionsEditorialTag.GET_EDITORIAL_TAG, ...params }
}

export function loadGetEditorialTag(data) {
  return {
    type: ActionsEditorialTag.LOAD_GET_EDITORIAL_TAG,
    data,
  }
}

// Function if LoadEditorialTagSuccess
export function loadEditorialTagSuccess(data) {
  return {
    type: ActionsEditorialTag.RES_GET_EDITORIAL_TAG,
    data,
  }
}
